import styled from '@emotion/styled'
import { Fragment, useState, useEffect } from 'react'
import { UsaStates } from 'usa-states'
import { Form } from 'react-final-form'
import { Flex, Box, Text, Image } from '@rebass/emotion'
import { SyncLoader } from 'react-spinners'
import { useRouter } from 'next/router'

import Modal from 'components/modal'
import { Field, SoloError, FormDark as StyledForm, Group } from 'components/forms'
import { PrimaryButton, LinkButton } from 'components/buttons'
import { useQuery } from 'services/utils'
import {
  composeValidators,
  firstNameIsRequired,
  firstNameIsAlpha,
  lastNameIsRequired,
  lastNameIsAlpha,
  emailIsRequired,
  isEmail,
  emailAvailable,
  phoneIsRequired,
  addressIsRequired,
  cityIsRequired,
  stateIsRequired,
  zipcodeIsRequired,
  cardNumberIsRequired,
  isCreditCard,
  expirationMonthIsRequired,
  expirationMonthIsValidWithExpirationYear,
  expirationYearIsRequired,
  expirationYearIsValidWithExpirationMonth,
  cvvIsRequired,
  isCvv,
  passwordIsRequired,
  passwordConfirmationIsRequired,
  passwordConfirmationMatch,
  agreeToTerms
} from 'services/validation'
import nexus from 'services/nexus'

const productDisplayMap = {
  navigator: 'Navigator',
  tracker: 'Tracker',
  navigatortracker: 'Navigator & Tracker'
}

const productPriceMap = {
  CSB2495: '$24.95',
  navigator: '$17.95',
  tracker: '$14.95',
  navigatortracker: '$29.95'
}

const CouponGroup = styled(Group)`
  background: #f2f2f2;
`

const Registration = ({ onComplete, product }) => {
  const [fusebillIds, setFusebillIds] = useState({})
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [error, setError] = useState()
  const [mode, setMode] = useState('cc')
  const [query, setQuery] = useState({})
  const [coupons, setCoupons] = useState([])
  const states = (new UsaStates()).states
  const currentYear = new Date().getFullYear() - 2000
  const endOfYears = currentYear + 20
  const years = []

  useEffect(() => {
    const defaultFusebillIds = window.localStorage.getItem('fusebillIds')
    if (defaultFusebillIds) setFusebillIds(JSON.parse(defaultFusebillIds))
  }, [])

  useEffect(() => {
    (async () => {
      setCoupons((await nexus.get('/coupons')).body)
    })()
  }, [])

  useEffect(() =>
    setQuery(useQuery())
  , [])

  const { affiliationType, email, migration = 0, pb = 0, navio = 0 } = query

  const router = useRouter()

  for (let i = currentYear; i <= endOfYears; i++) {
    years.push(i)
  }

  const onSubmit = async data => {
    try {
      if (data.pb == 0)
        data = { product, ...data }

      const { body } = await nexus
        .post(
          '/checkout'
        )
        .send({
          ...data,
          ...fusebillIds,
          expirationMonth: parseInt(data.expirationMonth),
          expirationYear: parseInt(data.expirationYear)
        })

      window.localStorage.removeItem('fusebillIds')

      if (navio == 1) router.push(`/navio?email=${email}`)
      else onComplete(body)
    } catch (e) {
      const { message, subscriptionCustomerId, navioCustomerId } = e.response.body
      const newFusebillIds = { subscriptionCustomerId, navioCustomerId }
      window.localStorage.setItem('fusebillIds', JSON.stringify(newFusebillIds))
      setFusebillIds(newFusebillIds)
      setError(message)
      setShowErrorModal(true)
    }
  }

  const getCoupon = value =>
    coupons.find(({ code }) => code === value )

  const shouldDisableSubmit = errors => {
    const errorKeys = [ ...Object.keys(errors) ]
      .filter(error => error != 'agree')

    return errorKeys.length
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ affiliationType, email, migration, pb }}>
      {({ handleSubmit, values, submitting, errors, active }) => {
        const selectedCoupon = getCoupon(values.coupon)

        return (
          <StyledForm onSubmit={handleSubmit}>
            <Group>
              <Box width={[1, 1 / 2]} mr={[0, 2]} mb={[15, 0]}>
                <Field
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  validate={composeValidators(firstNameIsRequired, firstNameIsAlpha)}
                />
              </Box>
              <Box width={[1, 1 / 2]} ml={[0, 2]}>
                <Field
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  validate={composeValidators(lastNameIsRequired, lastNameIsAlpha)}
                />
              </Box>
            </Group>
            <Group>
              <Box width={[1]}>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  validate={!migration && composeValidators(emailIsRequired, isEmail, emailAvailable)}
                  disabled={migration}
                />
              </Box>
            </Group>
            {!migration && (
              <Fragment>
                <Group>
                  <Box width={[1]}>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Password"
                      validate={passwordIsRequired}
                      autoComplete="off"
                    />
                  </Box>
                </Group>
                <Group>
                  <Box width={[1]}>
                    <Field
                      name="passwordConfirmation"
                      type="password"
                      placeholder="Password Confirmation"
                      validate={composeValidators(passwordConfirmationIsRequired, passwordConfirmationMatch(values.password))}
                      autoComplete="off"
                    />
                  </Box>
                </Group>
              </Fragment>
            )}
            <Group>
              <Box width={[1]}>
                <Field
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  validate={phoneIsRequired}
                />
              </Box>
            </Group>
            <Group>
              <Box width={[1]}>
                <Field
                  name="address"
                  type="text"
                  placeholder="Street Address"
                  validate={addressIsRequired}
                />
              </Box>
            </Group>
            <Group>
              <Box width={[1]}>
                <Field
                  name="city"
                  type="text"
                  placeholder="City"
                  validate={cityIsRequired}
                />
              </Box>
            </Group>
            <Group>
              <Box width={[1, 2 / 3]} mr={[0, 2]} mb={[15, 0]}>
                <Field
                  name="state"
                  type="select"
                  validate={stateIsRequired}
                >
                  <option value="" key="defaultState">
                    State
                  </option>
                  {states.map(({ name, abbreviation }) => (
                    <option value={abbreviation} key={`shippingState${abbreviation}`}>
                      {name}
                    </option>
                  ))}
                </Field>
              </Box>
              <Box width={[1, 1 / 3]} ml={[0, 2]}>
                <Field
                  name="zip"
                  type="text"
                  placeholder="Zip Code"
                  validate={zipcodeIsRequired}
                />
              </Box>
            </Group>

            {mode == 'cc' && pb == 0 && (
              <Fragment>
                {/*
                <Group width={[1]}>
                  <LinkButton onClick={() => setMode('paypal')}>
                    I want to subscribe with PayPal
                  </LinkButton>
                </Group>
                */}
                <Group>
                  <Box width={[1]}>
                    <Field
                      name="cardNumber"
                      type="text"
                      placeholder="Card Number"
                      validate={composeValidators(cardNumberIsRequired, isCreditCard)}
                    />
                  </Box>
                </Group>
                <Group>
                  <Box width={[1, 2 / 5]} mr={[0, 2]} mb={[15, 0]}>
                    <Field
                      name="expirationMonth"
                      type="select"
                      validate={composeValidators(expirationMonthIsRequired, value =>
                        expirationMonthIsValidWithExpirationYear(value, values)
                      )}
                    >
                      <option value="">Exp Month</option>
                      <option value="1">1 - January</option>
                      <option value="2">2 - February</option>
                      <option value="3">3 - March</option>
                      <option value="4">4 - April</option>
                      <option value="5">5 - May</option>
                      <option value="6">6 - June</option>
                      <option value="7">7 - July</option>
                      <option value="8">8 - August</option>
                      <option value="9">9 - September</option>
                      <option value="10">10 - October</option>
                      <option value="11">11 - November</option>
                      <option value="12">12 - December</option>
                    </Field>
                  </Box>
                  <Box width={[1, 2 / 5]} mx={[0, 2]} mb={[15, 0]}>
                    <Field
                      name="expirationYear"
                      type="select"
                      validate={composeValidators(expirationYearIsRequired, value =>
                        expirationYearIsValidWithExpirationMonth(value, values)
                      )}
                    >
                      <option value="">Expiration Year</option>
                      {years.map(y => (
                        <option value={y} key={y}>
                          {2000 + y}
                        </option>
                      ))}
                    </Field>
                  </Box>
                  <Box width={[1, 1 / 5]} ml={[0, 2]}>
                    <Field
                      name="cvv"
                      type="text"
                      placeholder="CVC"
                      validate={composeValidators(cvvIsRequired, isCvv)}
                    />
                  </Box>
                </Group>
                <Group>
                  <Box width={[1]}>
                    <Field
                      name="coupon"
                      type="text"
                      placeholder="Coupon"
                    />
                  </Box>
                </Group>
                {selectedCoupon && (
                  <CouponGroup p={[3]}>
                    Description: {selectedCoupon.description || selectedCoupon.name}
                  </CouponGroup>
                )}
                {!selectedCoupon && active !== 'coupon' && values.coupon && (
                  <CouponGroup p={[3]}>This coupon does not exist or has expired.</CouponGroup>
                )}

                <Group alignItems="center" mb={[30]}>
                  <Field
                    type="checkbox"
                    name="agree"
                    validate={agreeToTerms}
                    label={
                      <Text>I agree to the {' '}<a href="https://sat-portal.s3.us-east-2.amazonaws.com/static/Senior+Agent+Tools+Terms+of+Use+06-10-2020.pdf" target="_blank">Terms</a>{' '} and {' '}<a href="https://sat-portal.s3.us-east-2.amazonaws.com/static/Privacy+Policy.pdf" target="_blank">Privacy Policy</a>.</Text>
                    }
                  />
                </Group>

                <Group alignItems="center" justifyContent="center">
                  <Text>
                    I agree to the Senior Agent Tools license fee of {productPriceMap[values.coupon || product]}/mo
                  </Text>
                </Group>

                <Group justifyContent="center">
                  {!submitting && (
                    <PrimaryButton
                      type="submit"
                      py={[17]}
                      width={['100%']}
                    >
                      SIGN UP!
                    </PrimaryButton>
                  )}
                  <SyncLoader loading={submitting} color="#cccccc" />
                </Group>

                <Group alignItems="center" justifyContent="center">
                  <Image src={require('/public/img/ssl.png')} width={200} />
                </Group>
              </Fragment>
            )}

            {mode == 'paypal' && pb == 0 && (
              <Fragment>
                <Group width={[1]}>
                  <LinkButton onClick={() => setMode('cc')}>
                    I want to pay with a Credit Card
                  </LinkButton>
                </Group>

                <PrimaryButton
                  py={[17]}
                  width={['100%']}
                >
                  TAKE ME TO PAYPAL
                </PrimaryButton>
              </Fragment>
            )}

            {pb == 1 && (
              <Group justifyContent="center">
                {!submitting && (
                  <PrimaryButton
                    type="submit"
                    py={[17]}
                    width={['100%']}
                    disabled={Object.keys(errors).length}
                  >
                    SIGN UP!
                  </PrimaryButton>
                )}
                <SyncLoader loading={submitting} color="#cccccc" />
              </Group>
            )}

            <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)} title="Uh Oh! There's been a mishap!">
              <Text as="p">{error}. Check your submission and try again. If the issue persists please <a href="mailto:admin@senioragenttools.com">contact us</a>.</Text>
            </Modal>
          </StyledForm>
        )
      }}
    </Form>
  )
}

export default Registration
