import Head from 'next/head'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import YouTube from 'react-youtube'
import Hide from 'hidden-styled'
import { Fragment, useState, useEffect } from 'react'
import { Element, scroller } from 'react-scroll'
import { Global, css } from '@emotion/core'
import { Flex, Box, Text, Image } from '@rebass/emotion'

import FlexContainer from 'components/flex-container'
import Registration from 'components/registration'
import Modal from 'components/modal'
import { Default } from 'components/layouts'
import { PrimaryButton, SecondaryButton, LinkButton } from 'components/buttons'
import { useQuery } from 'services/utils'
import { portalUrl} from 'services/config'

const BigOption = styled(Box)`
  cursor: pointer;
  border: ${props => props.active ? '3px' : '1px'} solid ${props => props.active ? '#ffc000' : '#12324b'};
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  background-color: #fbfbf0;
  transition: all .75s;
  :hover {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  }
  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #749ebe;
    height: 75px;
    padding: 15px;
  }
  > header img {
    width: 200px;
  }
  p {
    color: #808080;
    font-size: 18px;
    line-height: 1.25;
  }
  ${props => props.active && `
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
    opacity: 1;
  `}
  h3 {
    font-weight: bold;
    font-size: 36px;
    font-style: italic;
    text-decoration: underline;
    color: #12324b;
  }
  ul {
    list-style-type: circle;
    margin-left: 20px;
    line-height: 25px;
  }
`

const RegBox = styled(FlexContainer)`
  border: 1px solid #12324b;
`

const InfoFlex = styled(Flex)`
  border: 3px solid #12324b;
  border-radius: 10px;
  padding: 15px;
`

const SignupPage = () => {
  const [user, setUser] = useState()
  const [product, setProduct] = useState()
  const [navigatorModalOpen, setNavigatorModalOpen] = useState(false)
  const [trackerModalOpen, setTrackerModalOpen] = useState(false)
  const [showMigrationModal, setShowMigrationModal] = useState(false)
  const [query, setQuery] = useState({})

  const { pb = 0, migration = 0, pbOnly = 0, affiliationType } = query

  useEffect(() =>
    setQuery(useQuery())
  , [])

  useEffect(() => {
    if (migration == 1) setShowMigrationModal(true)
  }, [query.migration])

  const changeProduct = product => {
    setProduct(product)
    scroller.scrollTo('checkout', {
      duration: 750,
      delay: 100,
      smooth: true
    })
  }

  return (
    <Default>
      <Global
        styles={css`
          body {
            background-color: #436e90;
          }
        `}
      />
      <Head>
        <title>Senior Agent Tools</title>
      </Head>

      {user ? (
        <FlexContainer flexDirection="column" maxWidth={[400]} py={[100]}>
          <Text fontSize={[16]} fontFamily="roboto" mb={[30]} lineHeight={[1.5]}>
            Congratulations! You have successfully registered for Senior Agent Tools.
          </Text>
          <SecondaryButton onClick={() => window.location = `${portalUrl}/services/tracker/login.php`}>Login</SecondaryButton>
        </FlexContainer>
      ) : (
        <Box px={[2, 0]}>

          <FlexContainer width={[1]} py={[3]} justifyContent="center" alignItems="center">
            <Image src={require('/public/img/sat-logo.png')} />
          </FlexContainer>

          {migration == 0 && (
            <Hide xs>
              <FlexContainer width={[1, 800]} mt={[0, -85]} mb={[0, 60]} pb={[30, 0]} justifyContent={['center', 'flex-end']} alignItems="center">
                  <SecondaryButton onClick={() => window.location = `${portalUrl}/services/tracker/login.php`}>Login</SecondaryButton>
              </FlexContainer>
            </Hide>
          )}

          <FlexContainer width={[1]} justifyContent="center" alignItems="center">
            {migration == 0 ? (
              <Text color="#ffffff" fontWeight={700} fontSize={[16, 24]} pb={[4]}>Success Tools For Senior Market Insurance Agents</Text>
            ) : (
              <Text color="#ffffff" fontWeight={700} fontSize={[16, 24]} pb={[4]} textAlign="center">Your login has been validated.<br />Continue by clicking "Subscribe Now" below the desired service(s).</Text>
            )}
          </FlexContainer>

          {migration == 0 && (
            <Hide sm md lg>
              <FlexContainer width={[1, 800]} mt={[0, -85]} mb={[0, 60]} pb={[30, 0]} justifyContent={['center', 'flex-end']} alignItems="center">
                  <SecondaryButton onClick={() => window.location = `${portalUrl}/services/tracker/login.php`}>Login</SecondaryButton>
              </FlexContainer>
            </Hide>
          )}

          {migration == 0 && (
            <Fragment>
              <FlexContainer alignItems="center" justifyContent="center">
                <Box width={[1, 200]} mb={[3]} mr={[0, 3]}>
                  <a href="https://apps.apple.com/us/app/navigator-lms/id1517734047" target="_blank">
                    <Image src={require('/public/img/on-app-store.svg')} />
                  </a>
                </Box>
                <Box width={[1, 200]} mb={[3]}>
                  <a href="https://play.google.com/store/apps/details?id=com.silverbow&hl=en_US&gl=US" target="_blank">
                    <Image src={require('/public/img/on-play-store.svg')} />
                  </a>
                </Box>
              </FlexContainer>

              <FlexContainer maxWidth={[800]}>
                <InfoFlex width={[1]} justifyContent="center" alignItems="center">
                  <Flex justifyContent="center" alignItems="center" flexDirection={['column', 'row']}>
                    <Flex flexDirection="column" alignItems="center" width={[1, 'auto']} mb={[25, 0]}>
                      <Image
                        src={require('/public/img/navigator-logo.png')}
                        height="75px !important"
                      />
                      <SecondaryButton mt={[3]} width={[1, 250]} onClick={() => setNavigatorModalOpen(true)}>Learn More</SecondaryButton>
                    </Flex>
                    <Flex flexDirection="column" ml={[0, 5]} alignItems="center" width={[1, 'auto']}>
                      <Image
                        src={require('/public/img/tracker-logo.png')}
                        height="75px !important"
                      />
                      <SecondaryButton mt={[3]} width={[1, 250]}  onClick={() => setTrackerModalOpen(true)}>Learn More</SecondaryButton>
                    </Flex>
                  </Flex>
                </InfoFlex>
              </FlexContainer>
            </Fragment>
          )}

          { pb == 0 && (
            <FlexContainer maxWidth={[800]} py={[50]} justifyContent={['center']}>
              <Flex flexWrap={['wrap', 'nowrap']}>
                <BigOption width={[1, 1]} mb={[3, 0]} active={product === 'navigatortracker'} onClick={() => changeProduct('navigatortracker')}>
                  <header><h3>Senior Agent Tools</h3></header>
                  <Box p={[3]} textAlign="center">
                    <Image src={require('/public/img/navigator-logo.png')} height="23px !important" />
                    <Text fontWeight="bold">+</Text>
                    <Image src={require('/public/img/tracker-logo.png')} height="23px !important" />
                    <Flex justifyContent="center" alignItems="flex-end" my={[25]}>
                      <Text color="#12324b">Only</Text>
                      <Text color="#12324b" fontWeight="bold" fontSize={30} mx={[1]}>$29.95</Text>
                      <Text color="#12324b" fontWeight="bold" fontSize={20}>/mo</Text>
                    </Flex>
                    <PrimaryButton>Subscribe Now!</PrimaryButton>
                  </Box>
                </BigOption>
              </Flex>
            </FlexContainer>
          )}

          <Element name="checkout" />
          <Fade when={pb == 1 || !!product}>
            <RegBox flexDirection="column" maxWidth={[800]} py={[10, 50]} px={[10, 100]} bg="#fbfbf0">
              {pb == 1 ? (
                <Fragment>
                  {affiliationType == 1 && (
                    <Flex justifyContent="center" mb={3}>
                      <Image src={require('/public/img/fex.png')} width={300} />
                    </Flex>
                  )}
                  {affiliationType == 4 && (
                    <Flex justifyContent="center" mb={3}>
                      <Image src={require('/public/img/cardinal-senior-benefits.png')} width={300} />
                    </Flex>
                  )}
                  {affiliationType == 5 && (
                    <Flex justifyContent="center" mb={3}>
                      <Image src={require('/public/img/cfc.jpg')} width={300} />
                    </Flex>
                  )}
                  <Text fontSize={[16]} fontFamily="roboto" mb={[30]} lineHeight={[1.5]}>
                    Complete the form below to register for the group Production Board. You will have immediate access upon successful registration. Login using the "Login" button at senioragenttools.com.
                  </Text>
                </Fragment>
              ) : (
                <Fragment>
                  <Text fontSize={[24]} fontWeight="bold" fontFamily="lato" mb={[15]}>Sign Up</Text>
                  <Text fontSize={[16]} fontFamily="roboto" mb={[30]} lineHeight={[1.5]}>
                    Complete the form below to register for access to Senior Agent Tools. You will have immediate access upon successful registration. Login using the "Login" button at senioragenttools.com.  Your credit/debit card will be charged monthly.  Cancel at any time using "Manage My Account" in the software menu or by sending us a cancellation request using our "Contact Us" feature.
                  </Text>
                </Fragment>
              )}

              <Registration onComplete={setUser} product={product} />
            </RegBox>
          </Fade>

          <Modal
            isOpen={navigatorModalOpen}
            onClose={() => setNavigatorModalOpen(false)}
            title="Navigator Details"
          >
            <Box mb={[3]}>
              <YouTube videoId="ftCb-jvbDCA" />
            </Box>
            <Box>
              Navigator Lead Management System is a cloud based lead management system specifically designed for senior market insurance agents. Navigator helps agents manage and maximize the conversion of their leads, and can be used for any type of lead including Direct Mail, Telemarket and Internet leads. Consisting of desktop, Android and iOS apps, your leads are at your fingertips both in the office and while on the go. Watch the short video below for an overview of how Navigator works.
            </Box>
          </Modal>

          <Modal
            isOpen={trackerModalOpen}
            onClose={() => setTrackerModalOpen(false)}
            title="Tracker Details"
          >
            <Box mb={[3]}>
              <YouTube videoId="OPe877XVlCA" />
            </Box>
            <Box>
              Tracker is a cloud based Book of Business management system designed specifically for Senior Market Life Insurance agents. Tracker helps you keep track of your Book of Business and provides you with important metrics that are critical to every successful agent.  Consisting of a desktop and mobile optimized web app, your entire book of business is at your fingertips whether in the office or on the go.  Watch the short video below for an overview of how Tracker works.
            </Box>
          </Modal>

          <Modal isOpen={showMigrationModal} onClose={() => setShowMigrationModal(false)} title="We're upgrading your account!">
            {pbOnly == 1 ? (
              <Text as="p">
                Senior Agent Tools
              </Text>
            ) : (
              <Text as="p">
                Senior Agent Tools
              </Text>
            )}
          </Modal>
        </Box>
      )}
    </Default>
  )
}

export default SignupPage
